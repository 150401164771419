import React from "react"
import Layout from "../components/Layout"
import SEO from "../components/seo"
import { injectIntl, Link, FormattedMessage } from "gatsby-plugin-intl"


const renderIt = () => {
  return <>
    <h4 className="display-medium font-300 text-primary mb-3">Avviso di Copyright</h4>    
      <p>
        The site's contents - script code, graphics, text, tables, images, sounds and any other information available in any form - are protected under the legislation on intellectual property © INMAGIK SRL 2014-2019.
      </p>
      <p>
        Every product or company mentioned in this site are trademarks of their respective owners and may be protected by patents and / or copyrights granted or registered by the authorities.
      </p>
      <p>
        Any redistribution or reproduction in whole or in part the form of the contents in any form is prohibited, with the following conditions:
        <ul>
          <li>
            printing or downloading on digital devices only for personal and non-commercial use
          </li>
          <li>
            forwarding the content to persons or to third parties for their personal use as long as you specify this site and INMAGIK SRL as content authors
          </li>
          <li>
          you can not use or distribute the content of the site for commercial purposes without the written consent of INMAGIK SRL
          </li>
        </ul>
      </p>

      <h5>
        Additional credits
      </h5>
      <p>
        All trademarks and logos reproduced in this website, which are not the property of, or licensed to the website operator, are acknowledged on the website.
      </p> 
  </>
}


const renderEn = () => {
  return <>
    <h4 className="display-medium font-300 text-primary mb-3">Copyright notice</h4>    
      <p>
        The site's contents - script code, graphics, text, tables, images, sounds and any other information available in any form - are protected under the legislation on intellectual property © INMAGIK SRL 2014-2019.
      </p>
      <p>
        Every product or company mentioned in this site are trademarks of their respective owners and may be protected by patents and / or copyrights granted or registered by the authorities.
      </p>
      <p>
        Any redistribution or reproduction in whole or in part the form of the contents in any form is prohibited, with the following conditions:
        <ul>
          <li>
            printing or downloading on digital devices only for personal and non-commercial use
          </li>
          <li>
            forwarding the content to persons or to third parties for their personal use as long as you specify this site and INMAGIK SRL as content authors
          </li>
          <li>
          you can not use or distribute the content of the site for commercial purposes without the written consent of INMAGIK SRL
          </li>
        </ul>
      </p>

      <h5>
        Additional credits
      </h5>
      <p>
        All trademarks and logos reproduced in this website, which are not the property of, or licensed to the website operator, are acknowledged on the website.
      </p> 
  </>
    
  
}



const Page = ({data, intl}) => {
  
  return (
  <Layout>
    <SEO title="Privacy policy - INMAGIK" />
    <div className="main-content container p-4 font-100">
      {intl.locale === 'it' ?  renderIt() : renderEn()}
    </div>
  </Layout>
)}

export default injectIntl(Page)
